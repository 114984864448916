import axios from 'axios';
import API_URL from '../backend'
import authHeader from './auth-header.service';


class PerfilesService {
    getTodos(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/perfiles/todos',
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    actualizarPerfil(perfil){
        const axiosRequest = {
            method: 'post',
            url: API_URL+'/admin/perfiles/update',
            data:perfil,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    nuevoPerfil(perfil){
        const axiosRequest = {
            method: 'post',
            url: API_URL+'/admin/perfiles/nuevo',
            data:perfil,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    borrarPerfil(perfilId){
        const axiosRequest = {
            method: 'delete',
            url: API_URL+'/admin/perfiles/borrar/'+perfilId,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }

}
export default new PerfilesService();