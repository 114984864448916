<template lang="html">
  <div class="v-home-perfiles p-2">
    <h3 class="text-left">Perfiles de la página principal</h3>
    <div class="d-flex flex-wrap mt-4">
      <Toolbar class="p-mb-4 col-12">
        <template #left>
          <Button
            label="Nuevo"
            icon="pi pi-plus"
            class="p-button-success p-mr-2"
            @click="
              editPerfil({
                ximg: '',
                xtitulo: '',
                xtitulo2: '',
                xdescripcion: '',
                xto: '',
                xvisible: false,
                xvideo: '',
              })
            "
          />
          <Button
            label="Borrar"
            icon="pi pi-trash"
            class="p-button-danger ml-2"
            :disabled="!selectedPerfiles || !selectedPerfiles.length"
            @click="confirmDeletePerfiles"
          />
        </template>
        <template #right> </template>
      </Toolbar>
      <DataTable
        :value="perfiles"
        :paginator="true"
        :expandedRows.sync="filasExpandidas"
        @row-expand="onRowExpand"
        class="p-datatable-customers"
        :rows="10"
        :resizableColumns="true"
        columnResizeMode="fit"
        dataKey="xperfilid"
        :rowHover="true"
        :selection.sync="selectedPerfiles"
        :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <template #header>
          <div class="table-header">
            Lista de Perfiles
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global']"
                placeholder="Búsqueda global"
              />
            </span>
          </div>
        </template>
        <template #empty> No perfiles found. </template>
        <template #loading> Loading perfiles data. Please wait. </template>
        <Column :expander="true" headerStyle="width: 3rem" />
        <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
        <Column field="xperfilid" header="Id" :sortable="true">
          <template #body="slotProps">
            <span class="p-column-title">Id</span>
            {{ slotProps.data.xperfilid }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xperfilid']"
              class="p-column-filter"
              placeholder="ID"
              style="width: 5em !important"
            />
          </template>
        </Column>
        <Column field="ximg" header="Img">
          <template #body="slotProps">
            <img
              style="max-height: 100px"
              :src="slotProps.data.ximg"
              @error="imagenError($event, slotProps)"
            />
          </template>
        </Column>
        <Column field="xicon" header="Icono" sortable>
          <template #body="slotProps">
            <span class="p-column-title">Icono</span>
            <i :class="slotProps.data.xicon" class="text-4xl"></i>
          </template>
        </Column>
        <Column
          header="Título"
          :sortable="true"
          sortField="xtitulo"
          filterField="xtitulo"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Título</span>
            {{ slotProps.data.xtitulo }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xtitulo']"
              class="p-column-filter"
              placeholder="Título"
            />
          </template>
        </Column>
        <Column
          header="Descripción"
          :sortable="true"
          sortField="xdescripcion"
          filterField="xdescripcion"
          filterMatchMode="in"
        >
          <template #body="slotProps">
            <span class="p-column-title">Descripción</span>
            {{ slotProps.data.xdescripcion }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xdescripcion']"
              class="p-column-filter"
              placeholder="Descripción"
            />
          </template>
        </Column>
        <Column
          header="Enlace"
          :sortable="true"
          sortField="xto"
          filterField="xto"
          filterMatchMode="in"
        >
          <template #body="slotProps">
            <span class="p-column-title">Enlace</span>
            {{ slotProps.data.xto }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xto']"
              class="p-column-filter"
              placeholder="Enlace"
            />
          </template>
        </Column>
        <Column
          field="xfecha_creacion"
          header="Fecha creación"
          sortField="xfecha_creacion"
          :sortable="true"
        >
          <template #body="slotProps">
            <span class="p-column-title">Fecha creación</span>
            <span>{{ slotProps.data.xfecha_creacion.split("T")[0] }}</span>
          </template>
        </Column>
        <Column field="xvisible" header="Visible" sortable>
          <template #body="slotProps">
            <span class="p-column-title">Visible</span>
            <vs-switch
              v-model="slotProps.data.xvisible"
              class="w-50"
              disabled
            />
          </template>
        </Column>
        <Column
          headerStyle="width: 8rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <Button
              type="button"
              icon="pi pi-cog"
              class="p-button-secondary"
              @click="editPerfil(slotProps.data)"
            ></Button>
            <Button
              type="button"
              icon="pi pi-trash"
              class="p-button-danger ml-2"
              @click="confirmDeletePerfil(slotProps.data)"
            ></Button>
          </template>
        </Column>
        <!-- EXPAND  -  EXPAND  -  EXPAND  -  EXPAND  -  EXPAND  -  EXPAND-->
        <template #expansion="slotProps">
          <h5 class="text-xl d-inline">
            Bloques del perfil {{ slotProps.data.xtitulo }}
          </h5>
          <vs-button
            class="d-inline ml-5"
            icon
            dark
            border
            @click="
              editBloque({
                xperfilid: slotProps.data.xperfilid,
                xtitulo: '',
                xtipo: 'p',
                xto: '',
                xvisible: false,
                xcontenido: '',
              })
            "
          >
            <i class="pi pi-plus"></i>&nbsp;&nbsp;
            <span class="text-lg"> Nuevo bloque</span>
          </vs-button>
          <DataTable
            :value="
              bloquesPerfiles.filter(
                (bloque) => bloque.xperfilid == slotProps.data.xperfilid
              )
            "
          >
            <Column
              field="xtitulo"
              header="Titulo"
              sortable
              headerClass="expand-column"
            >
              <template #body="slotProps">
                <span class="p-column-title">Título</span>
                {{ slotProps.data.xtitulo }}
              </template>
            </Column>
            <Column
              field="xtipo"
              header="Tipo"
              sortable
              headerClass="expand-column"
            >
              <template #body="slotProps">
                <span class="p-column-title">Tipo</span>
                {{ getTipo(slotProps.data.xtipo) }}
              </template>
            </Column>
            <Column
              field="xto"
              header="Enlace"
              sortable
              headerClass="expand-column"
            >
              <template #body="slotProps">
                <span class="p-column-title">Enlace</span>
                {{ slotProps.data.xto }}
              </template>
            </Column>
            <Column
              field="xfecha_modificacion"
              header="Fecha mod."
              sortable
              headerClass="expand-column"
            >
              <template #body="slotProps">
                <span class="p-column-title">Fecha mod.</span>
                {{ slotProps.data.xfecha_modificacion.split("T")[0] }}
              </template>
            </Column>
            <Column
              field="xvisible"
              header="Visible"
              sortable
              headerClass="expand-column"
            >
              <template #body="slotProps">
                <span class="p-column-title">Visible</span>
                <vs-switch
                  v-model="slotProps.data.xvisible"
                  class="w-50"
                  disabled
                />
              </template>
            </Column>
            <Column
              field="xorden"
              header="Orden"
              sortable
              headerClass="expand-column"
            >
              <template #body="slotProps">
                {{slotProps.data.xorden}}
              </template>
            </Column>
            <Column
              headerStyle="width: 5rem; text-align: center"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <vs-button
                  circle
                  icon
                  primary
                  flat
                  @click="editBloque(slotProps.data)"
                >
                  <i class="pi pi-cog"></i>
                </vs-button>
                <vs-button
                  circle
                  icon
                  danger
                  flat
                  @click="confirmDeleteBloque(slotProps.data)"
                >
                  <i class="pi pi-trash"></i>
                </vs-button>
              </template>
            </Column>
          </DataTable>
        </template>
      </DataTable>
      <!--DELETE BLOQUE DIALOG-->
      <Dialog
        :visible.sync="deleteBloqueDialog"
        :style="{ width: '450px' }"
        header="Confirmar"
        :modal="true"
        :baseZIndex="21001"
      >
        <div class="d-flex flex-column">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span> ¿Seguro que quieres borrar este bloque? </span>
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text text-white"
            @click="deleteBloqueDialog = false"
          />
          <Button
            label="Sí"
            icon="pi pi-check"
            class="p-button-danger"
            @click="deleteBloque"
          />
        </template>
      </Dialog>
      <!--BLOQUE DIALOG-->
      <Dialog
        :visible.sync="bloqueDialog"
        header="Editor de bloques de contenido"
        :modal="true"
        :maximizable="true"
        class="p-fluid col-11 col-lg-8"
        :baseZIndex="21001"
      >
        <div class="d-flex flex-wrap">
          <div class="p-field col-12 col-md-6 col-lg-5">
            <label for="titulo">Título</label>
            <InputText
              id="titulo"
              v-model.trim="bloque.xtitulo"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !bloque.xtitulo }"
            />
            <small class="p-invalid" v-if="submitted && !bloque.xtitulo"
              >Título is required.</small
            >
          </div>
          
          <div
            v-if="bloque.xtipo == 'p'"
            class="p-field col-12 col-md-6 col-lg-4"
          >
            <label for="description">Enlace</label>
            <InputText id="enlace" v-model.trim="bloque.xto" placeholder="/" />
          </div>

          <div class="p-field col-12 col-md-2 col-lg-1">
            <label for="description">Visible</label>
            <vs-switch
              v-model="bloque.xvisible"
              class="ml-auto mr-auto"
              style="max-width: 50px"
            />
          </div>
          <div class="p-field col-12 col-md-6">
            <label for="description">Tipo</label>
            <Dropdown
              v-model="bloque.xtipo"
              :options="tipos"
              optionValue="tipo"
              optionLabel="nombre"
              placeholder="Elije el tipo"
              @change="changeTipo($event)"
            />
          </div>
           <div class="p-field col-12 col-md-6">
            <label for="titulo">Orden</label>
            <InputNumber
              showButtons 
              id="titulo"
              v-model.trim="bloque.xorden"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !bloque.xtitulo }"
            />
            <small class="p-invalid" v-if="submitted && !bloque.xtitulo"
              >Título is required.</small
            >
          </div>
        </div>
        <div class="d-flex flex-wrap" v-if="bloque.xtipo == 'p'">
          <Textarea v-model="bloque.xcontenido"></Textarea>
        </div>
        <div class="d-flex flex-wrap ml-5 mr-5" v-if="bloque.xtipo == 'li'">
          <ul class="col-12 text-left">
            <li class="lista-item" v-for="(li, index) in lis" :key="li + index">
              {{ li.split("##")[0] }}
              <a
                v-if="li.split('##')[1] && li.split('##')[1].length > 0"
                :href="li.split('##')[1]"
                target="_blank"
                >Link</a
              >
              <vs-button
                icon
                circle
                flat
                success
                class="d-inline"
                @click="openLiEditor(li, index)"
              >
                <i class="pi pi-pencil"></i>
              </vs-button>
            </li>
          </ul>
          <vs-button icon success flat @click="lis.push('Click to edit##')">
            <i class="pi pi-plus"></i> Añadir
          </vs-button>
        </div>
        <div class="d-flex flex-wrap" v-if="bloque.xtipo == 'accordion'">
          <Accordion
            class="col-12 d-flex flex-wrap align-items-center"
            v-if="accordionstitulos.length > 0"
            :multiple="true"
          >
            <AccordionTab
              class="col-12"
              v-for="(titulo, index) in accordionstitulos"
              :key="index"
              :header="titulo"
            >
              <div class="d-flex flex-wrap align-items-center">
                <div class="col-10">
                  <p>{{ accordionstextos[index] }}</p>
                  <a
                    v-if="
                      accordionslinks[index] &&
                      accordionslinks[index].length > 0
                    "
                    :href="accordionslinks[index]"
                    target="_blank"
                    >Link</a
                  >
                </div>
                <div class="col-2">
                  <vs-button
                    icon
                    circle
                    flat
                    success
                    class="d-inline"
                    @click="openAccordionEditor(index)"
                  >
                    <i class="pi pi-pencil"></i>
                  </vs-button>
                  <vs-button
                    icon
                    circle
                    flat
                    danger
                    class="d-inline"
                    @click="
                      accordionstextos.splice(index, index);
                      accordionstitulos.splice(index, index);
                      accordionslinks.splice(index, index);
                    "
                  >
                    <i class="pi pi-trash"></i>
                  </vs-button>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
          <vs-button
            icon
            success
            flat
            @click="
              accordionstextos.push('Vacío');
              accordionstitulos.push('Click to edit');
              accordionslinks.push('');
            "
          >
            <i class="pi pi-plus"></i> Añadir
          </vs-button>
        </div>

        <div class="d-flex flex-wrap" v-if="bloque.xtipo == 'fieldset'">
          <Fieldset
            v-for="(titulo, index) in fieldsetstitulos"
            class="col-12"
            :key="index"
            :toggleable="true"
            :collapsed="true"
          >
            <template #legend>
              <span class="text-left text-xl">
                {{ titulo }}
              </span>
            </template>
            <div class="d-flex col-12 flex-wrap align-items-center">
              <div class="col-10 text-lg">
                <p>{{ fieldsetstextos[index] }}</p>
                <a
                  v-if="
                    fieldsetslinks[index] && fieldsetslinks[index].length > 0
                  "
                  :href="fieldsetslinks[index]"
                  target="_blank"
                  >Link</a
                >
              </div>
              <div class="col-2">
                <vs-button
                  icon
                  circle
                  flat
                  success
                  class="d-inline"
                  @click="openFieldsetEditor(index)"
                >
                  <i class="pi pi-pencil"></i>
                </vs-button>
                <vs-button
                  icon
                  circle
                  flat
                  danger
                  class="d-inline"
                  @click="
                    fieldsetstextos.splice(index, index);
                    fieldsetstitulos.splice(index, index);
                    fieldsetslinks.splice(index, index);
                  "
                >
                  <i class="pi pi-trash"></i>
                </vs-button>
              </div>
            </div>
          </Fieldset>
          <vs-button
            icon
            success
            flat
            @click="
              fieldsetstextos.push('Vacío');
              fieldsetstitulos.push('Click to edit');
              fieldsetslinks.push('');
            "
          >
            <i class="pi pi-plus"></i> Añadir
          </vs-button>
        </div>
        <div
          class="d-flex flex-wrap justify-content-center"
          v-if="bloque.xtipo == 'card'"
        >
          <div class="card" style="max-width: 400px">
            <img :src="bloque.ximg" class="card-img-top" />
            <div class="card-body">
              <div class="card-title text-center">
                <h3>{{ bloque.xtitulo }}</h3>
                <h4 class="fs-6 text-primary">{{ bloque.cardsSubtitulo }}</h4>
                <div
                  v-if="
                    bloque.cardsKitDigitalPrecioSin &&
                    bloque.cardsKitDigitalPrecioCon
                  "
                  class="text-left"
                >
                  <br />
                  <p class="fs-6">
                    <span>Precio de la implantación sin subvención </span>
                    <span style="text-decoration: line-through">{{
                      bloque.cardsKitDigitalPrecioSin
                    }}</span>
                  </p>
                  <p class="text-primary">
                    <span class="fs-1">{{
                      bloque.cardsKitDigitalPrecioCon
                    }}</span>
                    <span class="fs-5"> Precio con subvención</span>
                  </p>
                </div>
              </div>
              <div class="card-text text-left">
                <p>{{ bloque.cardsParrafo }}</p>
                <ul class="lista text-left">
                  <li
                    class="lista-item"
                    v-for="(li, i) in bloque.cardsLista"
                    :key="li + i"
                  >
                    {{ li }}
                  </li>
                </ul>
              </div>
              <div class="d-flex justify-content-end">
                <router-link v-if="bloque.xto" :to="bloque.xto" class="mb-2">
                  <Button label="Saber más" class="l-auto"></Button>
                </router-link>
              </div>

              <div class="card-footer text-muted">
                <p>{{ bloque.cardsFooter }}</p>
              </div>
            </div>
          </div>
          <vs-button
            icon
            circle
            flat
            success
            class="d-inline fs-6"
            @click="cardDialog = true"
            >Editar</vs-button
          >
        </div>

        <div
          class="d-flex flex-wrap justify-content-center"
          v-if="bloque.xtipo == 'img'"
          style="z-index: 21003"
        >
          <div>
            <vs-card type="4" class="col-12 d-flex justify-content-center">
              <template #img>
                <img
                  :src="bloque.ximg"
                  @error="imagenError($event)"
                  class="product-image"
                  style="max-height: 300px"
                  alt=""
                  @click="uploadImg"
                />
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  size="2000000"
                  @change="cambiarImgBloque"
                  ref="uploadImg"
                />
              </template>
              <template #interactions>
                <vs-button icon floating @click="uploadImg">
                  <i class="pi pi-images"></i>
                </vs-button>
              </template>
            </vs-card>
          </div>
        </div>
        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            class="p-button"
            @click="
              bloqueDialog = false;
              bloque = {};
            "
          />
          <Button
            label="Save"
            icon="pi pi-check"
            class="p-button"
            @click="saveBloque"
          />
        </template>
      </Dialog>

      <!--CARD DIALOG-->
      <Dialog
        :visible.sync="cardDialog"
        header="Editor de tarjeta (card)"
        :modal="true"
        class="p-fluid col-11 col-lg-5"
        :baseZIndex="21002"
      >
        <vs-card type="4" class="col-12 d-flex justify-content-center">
          <template #img>
            <img
              :src="bloque.ximg"
              @error="imagenError($event)"
              class="product-image"
              style="max-height: 300px"
              alt=""
              @click="uploadImg"
            />
            <input
              type="file"
              hidden
              accept="image/*"
              size="2000000"
              @change="cambiarImgBloque"
              ref="uploadImg"
            />
          </template>
          <template #interactions>
            <vs-button icon floating @click="uploadImg">
              <i class="pi pi-images"></i>
            </vs-button>
          </template>
        </vs-card>
        <div>
          <span class="text-left">Categoria</span>
          <InputText
            placeholder="Categoria"
            v-model.trim="bloque.xcategoria"
          ></InputText>

          <span class="text-left">Subtitulo</span>
          <InputText
            placeholder="Subtitulo"
            v-model.trim="bloque.cardsSubtitulo"
          ></InputText>
          <span class="text-left">Precio sin subvención (Kit digital)</span>
          <InputText
            placeholder="10.000€"
            v-model.trim="bloque.cardsKitDigitalPrecioSin"
          ></InputText>
          <span class="text-left">Precio con subvención (Kit digital)</span>
          <InputText
            placeholder="1.000€"
            v-model.trim="bloque.cardsKitDigitalPrecioCon"
          ></InputText>
          <span class="text-left">Párrafo info</span>
          <Textarea
            placeholder="Info"
            v-model.trim="bloque.cardsParrafo"
          ></Textarea>
          <span class="text-left d-inline-block mt-5">Lista info</span>
          <ul class="lista">
            <li
              class="text-left lista-item"
              v-for="(li, i) in bloque.cardsLista"
              :key="li + i"
            >
              {{ li }}
              <vs-button
                icon
                circle
                flat
                success
                class="d-inline"
                @click="openCardLiEditor(li, i)"
              >
                <i class="pi pi-pencil"></i>
              </vs-button>
              <vs-button
                v-tooltip="'Eliminar item'"
                icon
                circle
                flat
                danger
                class="d-inline"
                @click="elilminarItemCardLi(i)"
              >
                <i class="pi pi-trash"></i>
              </vs-button>
            </li>
          </ul>
          <Button
            label="Añadir más"
            @click="openCardLiEditor('', bloque.cardsLista.length)"
          ></Button>

          <span class="text-left d-inline-block mt-5">Footer</span>
          <InputText
            placeholder="Pie de página"
            v-model.trim="bloque.cardsFooter"
          ></InputText>
        </div>

        <template #footer>
          <Button
            label="Cerrar"
            icon="pi pi-times"
            class="p-button"
            @click="cardDialog = false"
          />
        </template>
      </Dialog>
      <!--LISTA DIALOG-->
      <Dialog
        :visible.sync="liDialog"
        header="Editor de lista"
        :modal="true"
        class="p-fluid col-11 col-lg-4"
        :baseZIndex="21002"
      >
        <div>
          <InputText placeholder="Lista" v-model.trim="li.text"></InputText>
        </div>
        <template #footer>
          <Button
            label="Guardar"
            icon="pi pi-check"
            class="p-button"
            @click="
              lis[li.index] = li.text;
              liDialog = false;
              li = {};
            "
          />
        </template>
      </Dialog>
      <!-- EDITOR LISTA CARDS -->
      <Dialog
        :visible.sync="cardLiDialog"
        header="Editor de lista"
        :modal="true"
        class="p-fluid col-11 col-lg-4"
        :baseZIndex="21002"
      >
        <div>
          <span class="text-left">Texto</span>
          <InputText placeholder="Lista" v-model.trim="li.text"></InputText>
        </div>
        <template #footer>
          <Button
            label="Guardar"
            icon="pi pi-check"
            class="p-button"
            @click="
              bloque.cardsLista[li.index] = li.text;
              cardLiDialog = false;
              li = {};
            "
          />
        </template>
      </Dialog>
      <!--Borrar card li-->
      <Dialog
        :visible.sync="cardBorrarLi"
        header="Eliminar lista"
        :modal="true"
        class="p-fluid col-11 col-lg-4"
        :baseZIndex="21002"
      >
        <div>Se dispone a eliminar un item</div>
        <template #footer>
          <Button
            label="Elimianr"
            icon="pi pi-check"
            class="p-button"
            @click="
              if (indexLiBorrar == 0) bloque.cardsLista = [];
              else bloque.cardsLista.splice(indexLiBorrar, indexLiBorrar);
              cardBorrarLi = false;
            "
          />
        </template>
      </Dialog>
      <!--ACCORDION DIALOG-->
      <Dialog
        :visible.sync="accordionDialog"
        header="Editor de Acordeón"
        :modal="true"
        class="p-fluid col-11 col-lg-5"
        :baseZIndex="21002"
      >
        <div class="d-flex flex-wrap">
          <InputText
            class="col-12"
            placeholder="Título"
            v-model.trim="accordion.titulo"
          ></InputText>
          <Textarea
            class="col-12 mt-2"
            placeholder="Contenido"
            v-model.trim="accordion.texto"
          ></Textarea>
        </div>
        <template #footer>
          <Button
            label="Guardar"
            icon="pi pi-check"
            class="p-button"
            @click="
              accordionstextos[accordion.index] = accordion.texto;
              accordionstitulos[accordion.index] = accordion.titulo;
              accordionDialog = false;
              accordion = {};
            "
          />
        </template>
      </Dialog>
      <!--FIELDSET DIALOG-->
      <Dialog
        :visible.sync="fieldsetDialog"
        header="Editor de Acordeón"
        :modal="true"
        class="p-fluid col-11 col-lg-5"
        :baseZIndex="21002"
      >
        <div class="d-flex flex-wrap">
          <InputText
            class="col-12"
            placeholder="Título"
            v-model.trim="fieldset.titulo"
          ></InputText>
          <Textarea
            class="col-12 mt-2"
            placeholder="Contenido"
            v-model.trim="fieldset.texto"
          ></Textarea>
        </div>
        <template #footer>
          <Button
            label="Guardar"
            icon="pi pi-check"
            class="p-button"
            @click="
              fieldsetstextos[fieldset.index] = fieldset.texto;
              fieldsetstitulos[fieldset.index] = fieldset.titulo;
              fieldsetDialog = false;
              fieldset = {};
            "
          />
        </template>
      </Dialog>
      <!--DELETE PERFILES DIALOG-->
      <Dialog
        :visible.sync="deletePerfilesDialog"
        :style="{ width: '450px' }"
        header="Confirmar"
        :modal="true"
        :baseZIndex="21001"
      >
        <div class="d-flex flex-column">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span> ¿Seguro que quieres borrar estos perfiles? </span>
          <ul class="text-left ml-auto mr-auto">
            <li v-for="perfil in selectedPerfiles" :key="perfil.xperfilid">
              Id: {{ perfil.xperfilid }}
            </li>
          </ul>
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text text-white"
            @click="deletePerfilesDialog = false"
          />
          <Button
            label="Sí"
            icon="pi pi-check"
            class="p-button-danger"
            @click="deletePerfiles"
          />
        </template>
      </Dialog>
      <!--PERFIL DIALOG-->
      <Dialog
        :visible.sync="perfilDialog"
        header="Perfil Detalle"
        :modal="true"
        class="p-fluid col-11 col-lg-8"
        :baseZIndex="21001"
      >
        <TabView>
          <TabPanel header="Detalle">
            <div class="d-flex flex-wrap">
              <vs-card type="4" class="col-12 d-flex justify-content-center">
                <template #img>
                  <img
                    :src="perfil.ximg"
                    @error="imagenError($event, perfil)"
                    class="product-image"
                    style="max-height: 300px"
                    alt=""
                  />
                </template>
                <template #interactions>
                  <vs-button icon floating @click="uploadImg">
                    <i class="pi pi-images"></i>
                  </vs-button>
                </template>
              </vs-card>
              <input
                type="file"
                hidden
                accept="image/*"
                size="2000000"
                @change="cambiarImg"
                ref="uploadImg"
              />
              <div class="col-4 col-md-3"></div>
              <span
                class="p-3 shadow-2 mb-3 display-block perfil-icon col-4 col-md-2"
                style="border-radius: 10px"
              >
                <i
                  :class="perfil.xicon"
                  class="product-image text-7xl text-primary"
                  style="max-height: 300px"
                  alt=""
                ></i>
              </span>
              <div class="col-4 col-md-1"></div>

              <div class="p-field col-12 col-md-4">
                <label for="icon"
                  >Icono
                  <a
                    href="https://www.primefaces.org/primevue/showcase-v2/#/icons"
                    target="_blank"
                    ><i class="pi pi-external-link"></i></a
                ></label>
                <AutoComplete
                  id="icono"
                  v-model.trim="perfil.xicon"
                  required="true"
                  field="name"
                  autofocus
                  :dropdown="true"
                  :suggestions="filteredIcons"
                  @complete="searchIcon($event)"
                  @item-select="selectIcon($event)"
                  :class="{ 'p-invalid': submitted && !perfil.xtitulo }"
                >
                  <template #item="slotProps">
                    <div class="d-flex justify-center-start align-items-center">
                      <i :class="slotProps.item.name.toLowerCase()"></i>
                      <div class="ml-2">{{ slotProps.item.name }}</div>
                    </div>
                  </template>
                </AutoComplete>
                <small class="p-invalid" v-if="submitted && !perfil.xtitulo"
                  >Título is required.</small
                >
              </div>
              <div class="p-field col-12 col-lg-4">
                <label for="titulo">Título</label>
                <InputText
                  id="titulo"
                  v-model.trim="perfil.xtitulo"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !perfil.xtitulo }"
                />
                <small class="p-invalid" v-if="submitted && !perfil.xtitulo"
                  >Título is required.</small
                >
              </div>
              <div class="col-12 col-lg-8">
                <label for="descripcion">Descripción</label>
                <Textarea v-model="perfil.xdescripcion" class="w-100" />
              </div>

              <div class="p-field col-12 col-lg-8">
                <label for="description">Enlace</label>
                <InputText
                  id="enlace"
                  v-model.trim="perfil.xto"
                  placeholder="/"
                />
              </div>
              <div class="p-field col-12 col-lg-4">
                <label for="description">Visible</label>
                <vs-switch
                  v-model="perfil.xvisible"
                  class="ml-auto mr-auto"
                  style="max-width: 50px"
                />
              </div>
            </div>
          </TabPanel>

          <TabPanel header="Folletos">
            <vs-button
              @click="
                folletoDialog = true;
                folleto = {
                  xperfilid: perfil.xperfilid,
                  xdireccion: '',
                  xnombre: '',
                  ximg: '',
                };
                subirPDF = false;
              "
            >
              <span class="text-lg"
                ><i class="pi pi-cloud-upload"></i> Subir folleto</span
              >
            </vs-button>

            <input hidden type="file" ref="uploadImgFolleto" />
            <div class="d-flex flex-wrap justify-content-around">
              <vs-card
                type="2"
                v-for="folleto in folletos"
                :key="folleto.xfolletoid"
                class="mb-3"
              >
                <template #title>
                  <h3>{{ folleto.xnombre }}</h3>
                </template>
                <template #img>
                  <img
                    :src="folleto.ximg"
                    alt="Folleto img"
                    @error="imagenError($event)"
                  />
                </template>
                <template #text>
                  <p>&nbsp;</p>
                </template>
                <template #interactions>
                  <vs-button
                    icon
                    danger
                    @click="deleteFolleto(folleto.xfolletoid)"
                  >
                    <i class="pi pi-times"></i>
                  </vs-button>
                </template>
              </vs-card>
            </div>
          </TabPanel>
        </TabView>

        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            class="p-button"
            @click="hideDialog"
          />
          <Button
            label="Save"
            icon="pi pi-check"
            class="p-button"
            @click="savePerfil"
          />
        </template>
      </Dialog>
      <!-- FOLLETO DIALOG -->
      <Dialog
        :visible.sync="folletoDialog"
        header="Folleto nuevo"
        :modal="true"
        class="p-fluid col-6"
        :baseZIndex="21001"
      >
        <InputText
          v-model.trim="folleto.xnombre"
          placeholder="Nombre"
          class="col-12"
        />
        <div class="mb-4 mt-3 ml-auto mr-auto" style="width: fit-content">
          <vs-card type="1">
            <template #title>
              <h3>{{ folleto.xnombre }}</h3>
            </template>
            <template #img>
              <img
                :src="folleto.ximg"
                alt="Folleto img"
                @error="imagenError($event)"
              />
            </template>
            <template #text>
              <p>&nbsp;</p>
              <Message :closable="false" severity="success" v-if="subirPDF"
                >PDF adjuntado</Message
              >
              <Message :closable="false" severity="warn" v-if="!subirPDF"
                >PDF no adjuntado</Message
              >
            </template>
            <template #interactions>
              <vs-button icon @click="$refs.uploadFolletoImg.click()">
                <i class="pi pi-image"></i>
              </vs-button>
              <vs-button shadow primary @click="$refs.uploadFolletoPDF.click()">
                <i class="pi pi-file-pdf"></i>
              </vs-button>
            </template>
          </vs-card>
        </div>

        <input
          type="file"
          hidden
          ref="uploadFolletoImg"
          accept="image/*"
          size="500000"
          @change="cambiarImgFolleto"
        />

        <input
          type="file"
          hidden
          ref="uploadFolletoPDF"
          @change="cambiarPDF"
          accept="application/pdf"
          size="1000000"
        />
        <template #footer>
          <vs-button
            class="text-lg ml-auto mr-auto"
            :disabled="!subirPDF"
            @click="
              subirFolleto();
              folletoDialog = false;
            "
          >
            <span><i class="pi pi-upload"></i> Subir folleto</span>
          </vs-button>
        </template>
      </Dialog>
    </div>
    <!--<Toast position="bottom-center" group="PDF" :baseZIndex="21001" />-->
    <ConfirmPopup></ConfirmPopup>
  </div>
</template>

<script lang="js">
import API_URL from"../../backend.js"
import PerfilesService from '../../services/perfiles.service'
import PerfilesBloquesService from '../../services/perfilesBloques.services'
import DescargableService from '../../services/descargables.service'
import ViewService from "../../services/view.service.js"
  export default  {
    name: 'v-home-perfiles',
    props: [],
    mounted () {
      this.cargarPerfiles();
      this.icons = import("../../assets/icons.js").then((icons)=>{
        this.icons = icons.default
      })
    },
    data () {
      return {
        loader:null,
        perfiles:[],
        selectedPerfiles:[],
        filters: {},
        perfil:{},
        deletePerfilesDialog:false,
        perfilDialog:false,
        submitted:false,
        counterDanger: false,
        filteredIcons:null,
        icons:null,
        filasExpandidas:[],

        folletos:[],
        folletoDialog:false,
        folleto:{},
        subirPDF:false,

        bloquesPerfiles:[],
        bloque:{},
        selectedBloque:{},
        bloqueDialog:false,
        deleteBloqueDialog:false,
        tipos:[
          {
            tipo:'p',
            nombre: 'Párrafo'
          },
          {
            tipo:'card',
            nombre:'Tarjeta - Card'
          },
          {
            tipo:'li',
            nombre: 'Lista'
          },
          {
            tipo:'accordion',
            nombre: 'Acordeón'
          },
          {
            tipo:'img',
            nombre: 'Imágen'
          },
          /* {
            tipo:'fieldset',
            nombre: 'Fieldset'
          }, */
        ],
        lis:[],
        li:{},
        liDialog:false,
        accordionstextos:[],
        accordionstitulos:[],
        accordionslinks:[],
        accordion:{},
        accordionDialog:false,
        fieldsetstextos:[],
        fieldsetstitulos:[],
        fieldsetslinks:[],
        fieldset:{},
        fieldsetDialog:false,

        cardDialog:false,
        cardLiDialog:false,
        cardBorrarLi:false,
        indexLiBorrar :0,
      }
    },
    methods: {
       elilminarItemCardLi(index){
         console.log(index);
        this.cardBorrarLi = true;
        this.indexLiBorrar = index;
      },
      changeTipo(event){
        console.log(this.bloque)
        if(event.value == 'card' && this.bloque.xcontenido.length ==0 ){
          this.bloque.cardsSubtitulo ="Subtitulo";
          this.bloque.cardsKitDigitalPrecioSin ="10.000€";
          this.bloque.cardsKitDigitalPrecioCon ="1.000€";
          this.bloque.cardsParrafo ="Esto es un parrafo descriptivo de prueba";
          this.bloque.cardsLista =["Lista 1"];
          this.bloque.cardsFooter ="pequeño footer para aclaraciones";
        }else if(event.value == 'img'){
          if(!this.bloque.ximg){
            this.bloque.ximg = "/images/fondo_gris.jpg"
          }
        }
      },
      imagenError(event){
        console.log(event.target.src)
        event.target.src = "/images/fondo_gris.jpg"
      },
      deleteFolleto(xfolletoid){
        DescargableService.borrarFolleto(xfolletoid).then((res)=>{
          this.$toast.add({
            severity:res.data.success ? 'info' : 'error',
            summary: res.data.success ? 'Folleto borrado':'Error al borrar el folleto '+xfolletoid,
            life:res.data.success ? 5000 : null
          })
          this.folletoDialog = false;
          DescargableService.getFolletosInfoPerfiles(this.perfil.xperfilid).then((response) => {
            this.folletos = response.data
            this.folletos.forEach((folleto)=>{
              folleto.ximg = API_URL+folleto.ximg
              folleto.xdireccion = API_URL+folleto.xdireccion
            });
          })
        }).catch((error)=>{
          console.log(error)
        })
      },
      subirFolleto(){
        let formData = new FormData();
        formData.append("xperfilid", this.folleto.xperfilid);
        formData.append("xnombre", this.folleto.xnombre);
        formData.append("subirPDF", this.folleto.subirPDF);
        formData.append("subirImg", this.folleto.subirImg);
        DescargableService.postSubirFolletoPerfiles(formData).then((response)=>{
          this.$toast.add({
            severity: response.data.success ? 'info' : 'warning',
            summary: response.data.success ? 'Folleto añadido correctamente':'Error',
            life: response.data.success ? 10000 : null
          })
          DescargableService.getFolletosInfoPerfiles(this.perfil.xperfilid).then((response) => {
            this.folletos = response.data
            this.folletos.forEach((folleto)=>{
              folleto.ximg = API_URL+folleto.ximg
              folleto.xdireccion = API_URL+folleto.xdireccion
            });
          })
        }).catch((error)=>{
          console.error(error);
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error.message
          })
        })
      },
      cambiarImgFolleto(){
        const [file] = this.$refs.uploadFolletoImg.files;
        if(file.size>5000000){//5MB
          this.$toast.add({
            severity:'error',
            summary:'Tamaño del archivo',
            detail:'La imagen no puede pesar más de 5MB',
            life:10000
          })
          return;
        }
        if(file.type.split("/")[0] != 'image'){
          this.$toast.add({
            severity:'error',
            summary:'Tipo de archivo',
            detail:'La archivo no es una imagen',
            life:10000
          })
          return;
        }
        this.folleto.ximg = URL.createObjectURL(file);
        this.folleto.subirImg = file;
      },
      cambiarPDF(){
        const [file] = this.$refs.uploadFolletoPDF.files;
        if(file.size>10000000){//10MB
          this.$toast.add({
            severity:'error',
            summary:'Tamaño del archivo',
            detail:'El PDF no puede pesar más de 5MB',
            life:10000
          })
          return;
        }
        if(file.type != 'application/pdf'){
          this.$toast.add({
            severity:'error',
            summary:'Tipo de archivo',
            detail:'La archivo no es PDF',
            life:10000
          })
          return;
        }
        this.folleto.xdireccion = URL.createObjectURL(file);
        this.folleto.subirPDF = file;
        this.subirPDF = file;

      },
      openAccordionEditor(index){
        this.accordion.texto = this.accordionstextos[index];
        this.accordion.titulo = this.accordionstitulos[index];
        this.accordion.link = this.accordionslinks[index];
        this.accordion.index = index;
        this.accordionDialog = true;
      },
      openFieldsetEditor(index){
        this.fieldset.texto = this.fieldsetstextos[index];
        this.fieldset.titulo = this.fieldsetstitulos[index];
        this.fieldset.link = this.fieldsetslinks[index];
        this.fieldset.index = index;
        this.fieldsetDialog = true;
      },
      openLiEditor(li, index){
        this.li.text = li.split('##')[0];
        this.li.link = li.split('##')[1];
        this.li.index = index;
        this.liDialog = true;
      },
      openCardLiEditor(li, index){
        this.li.text = li;
        this.li.index = index;
        this.cardLiDialog = true;
      },
      closeLiEditor(){
        this.li={};
        this.liDialog = false;
      },
      getTipo(xtipo){
        if(xtipo == 'li') return 'Lista';
        if(xtipo == 'p') return 'Párrafo';
        if(xtipo == 'card') return 'Tarjeta';
        if(xtipo == 'accordion') return 'Acordeones';
        if(xtipo == 'fieldset') return 'Fieldset';
        if(xtipo == 'card') return 'Card';
      },
      searchIcon(event) {
        setTimeout(() => {
          if (!event.query.trim().length) {
            this.filteredIcons = [...this.icons];
          }
          else {
            this.filteredIcons = this.icons.filter((icon) => {
                return icon.name.toLowerCase().startsWith(event.query.toLowerCase());
            });
          }
        }, 250);
      },
      selectIcon(event){
        this.perfil.xicon=event.value.name
      },
      cargarPerfiles(){
        ViewService.getDatosWeb().then((response)=>{
          const logo = response.data[0].xlogo;
         this.loader = this.$vs.loading({
          type: "square",
          color: "#f8b505",
        });
        setTimeout(() => {
          let loaderDIV = document.getElementsByClassName(
            "vs-loading__load__animation"
          )[0];
          loaderDIV.innerHTML = "";
          const imgLogo = document.createElement("img");
          imgLogo.src = API_URL+logo;
          imgLogo.style = "width:200px; z-index: 99999999; border-radius:10px; transition: width 0.4s ease-in-out";

          loaderDIV.addEventListener("mouseover", (e) => {
            e.target.style.width="300px";
          })
          loaderDIV.addEventListener("mouseout", (e) => {
            e.target.style.width="200px";
          })
          loaderDIV.appendChild(imgLogo);
        }, 5);
        });


        PerfilesBloquesService.getTodos().then((response) => {
          this.perfiles = response.data.perfiles;
          this.bloquesPerfiles = [];
          this.perfiles.forEach((perfil) => {
            perfil.ximg= API_URL+perfil.ximg
            perfil.xvisible = perfil.xvisible == 1 ? true : false
          })
          response.data.bloques.forEach((arrayBloques) => {
            arrayBloques.forEach((bloque) => {
              if(bloque.ximg){
                bloque.ximg= API_URL+bloque.ximg
              }
              bloque.xvisible = bloque.xvisible == 1 ? true : false
            })
            this.bloquesPerfiles = this.bloquesPerfiles.concat(arrayBloques)
          })
          this.loader.close()
        }).catch((err) => {
          console.log(err)
          this.loader.close()
        })
      },
      filterDate(value, filter) {
        if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
            return true;
        }
        if (value === undefined || value === null) { return false; }
        return value === this.formatDate(filter);
      },
      confirmDeletePerfil(perfil) {
        this.selectedPerfiles = [perfil]
        this.deletePerfilesDialog = true;
      },
      confirmDeletePerfiles() {
        this.deletePerfilesDialog = true;
      },
      deletePerfiles(){
        let promesasDelete=[];
        for(let i = 0; i<this.selectedPerfiles.length; i++) {
          promesasDelete.push(PerfilesService.borrarPerfil(this.selectedPerfiles[i].xperfilid))
        }
        Promise.all(promesasDelete).then((res) => {
          res.forEach((response) => {
            if(response.data){
              this.$toast.add({
                severity: response.data.success ? 'info' : 'error',
                summary: 'Borrado',
                detail: response.data.description,
                life: response.data.success ? 4000 : null
              })
            }
          })
          this.cargarPerfiles();
          this.deletePerfilesDialog = false;
        })
      },
      editPerfil(perfil) {
        this.perfil = {...perfil};
        DescargableService.getFolletosInfoPerfiles(this.perfil.xperfilid).then((response) => {
          this.folletos = response.data
          this.folletos.forEach((folleto)=>{
          folleto.ximg = API_URL+folleto.ximg
          folleto.xdireccion = API_URL+folleto.xdireccion
        });
        })
        this.perfilDialog = true;
      },
      hideDialog() {
        this.perfilDialog = false;
        this.submitted = false;
        this.perfil = {}
      },
      savePerfil(){
        console.log(this.perfil)
        const formData = new FormData()
        formData.append("xtitulo", this.perfil.xtitulo)
        formData.append("xdescripcion", this.perfil.xdescripcion)
        formData.append("xicon", this.perfil.xicon)
        formData.append("xvisible", this.perfil.xvisible)
        formData.append("xto", this.perfil.xto)
        if(this.bloque.xcategoria){
          formData.append('xcategoria',this.bloque.xcategoria);
        }
        if(this.perfil.xperfilid) {
          formData.append("xperfilid", this.perfil.xperfilid)
        }
        if(this.perfil.subirVideo) {
          if(this.perfil.subirVideo)
          formData.append("subirVideo", this.perfil.subirVideo)
        }
        if(this.perfil.subirImg) formData.append("subirImg", this.perfil.subirImg)

        if(this.perfil.xperfilid){
          //update
          console.log(this.perfil)
          PerfilesService.actualizarPerfil(formData).then((response)=>{
            console.log(response)
            this.$toast.add({
              severity:response.data.success ? 'info' : 'error',
              summary:response.data.success ? 'Perfil actualizado' : 'Error',
              detail:response.data.description,
              life:response.data.success ? 3000 : null
            })
            this.cargarPerfiles()
            this.perfilDialog = false;
          }).catch((error)=>{
            console.log(error)
            this.$toast.add({
              severity:'error',
              summary:'Error',
              detail:error
            })
          });
        }else{
          //insert
          PerfilesService.nuevoPerfil(formData).then((response)=>{
            this.$toast.add({
              severity:response.data.success ? 'info' : 'error',
              summary:response.data.success ? 'Perfil creado' : 'Error',
              detail:response.data.description,
              life:response.data.success ? 3000 : null
            })
            this.cargarPerfiles();
            this.perfilDialog = false;
          }).catch((error)=>{
            console.log(error)
            this.$toast.add({
              severity:'error',
              summary:'Error',
              detail:error
            })
          });
        }
      },
      uploadVideo(){
        this.$refs.uploadVideo.click();
      },
      uploadImg(){
        this.$refs.uploadImg.click();
      },
      cambiarVideo(){
        const [file] = this.$refs.uploadVideo.files;
        if(file.size>50000000){//50MB
          this.$toast.add({
            severity:'error',
            summary:'Tamaño del archivo',
            detail:'El video no puede pesar más de 50MB',
            life:10000
          });
          return;
        }
        if(!file.type.includes("video/")){
          this.$toast.add({
            severity:'error',
            summary:'Tipo de archivo',
            detail:'El archivo debe ser de tipo video',
            life:10000
          })
          return;
        }

        this.perfil.xvideo = URL.createObjectURL(file);
        this.perfil.subirVideo = file;
        this.perfil.ximg = null;
        this.perfil.subirImg = null;
      },
      cambiarImg(){
        const [file] = this.$refs.uploadImg.files;
        if(file.size>10000000){//10MB
          this.$toast.add({
            severity:'error',
            summary:'Tamaño del archivo',
            detail:'La imagen no puede pesar más de 10MB',
            life:10000
          })
          return;
        }
        if(!file.type.includes("image/")){
          this.$toast.add({
            severity:'error',
            summary:'Tipo de archivo',
            detail:'El archivo debe ser de tipo imagen',
            life:10000
          })
          return;
        }

        this.perfil.ximg = URL.createObjectURL(file);
        this.perfil.subirImg = file;
        this.perfil.subirVideo = null;
        this.perfil.xvideo = null;

      },
      cambiarImgBloque(){
        const [file] = this.$refs.uploadImg.files;
        if(file.size>10000000){//10MB
          this.$toast.add({
            severity:'error',
            summary:'Tamaño del archivo',
            detail:'La imagen no puede pesar más de 10MB',
            life:10000
          })
          return;
        }
        if(!file.type.includes("image/")){
          this.$toast.add({
            severity:'error',
            summary:'Tipo de archivo',
            detail:'El archivo debe ser de tipo imagen',
            life:10000
          })
          return;
        }

        this.bloque.ximg = URL.createObjectURL(file);
        this.bloque.subirImg = file;


      },
      onRowExpand() {
        console.log('');
      },
      editBloque(bloque) {
        this.bloque = {...bloque};
        if(bloque.xtipo == 'li'){
          this.lis = bloque.xcontenido.split('&&');
        }else if(bloque.xtipo == 'accordion'){
            this.accordionstextos = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[1]);
            this.accordionstitulos = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[0])
            this.accordionslinks = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[2])
          }else if(bloque.xtipo == 'fieldset'){
              this.fieldsetstextos = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[1])
              this.fieldsetstitulos = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[0])
              this.fieldsetslinks = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[2])
            }else if(bloque.xtipo == 'card'){
              this.bloque.cardsSubtitulo = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[0])[0];
              this.bloque.cardsKitDigitalPrecioSin = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[1])[0];
              this.bloque.cardsKitDigitalPrecioCon = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[2])[0];
              this.bloque.cardsParrafo = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[3])[0];
              let bloqueLista = bloque.xcontenido.split('&&').map((str)=> str = str.split("##")[4]);
              if(bloqueLista && bloqueLista.length>0 && bloqueLista[0]!=undefined){
                this.bloque.cardsLista = bloqueLista[0].split("||");
              }else{
                this.bloque.cardsLista = null;
              }
              this.bloque.cardsFooter = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[5])[0];
            }
        this.bloqueDialog = true;
      },
      hideBloqueDialog() {
        this.bloqueDialog = false;
        this.submitted = false;
        this.bloque = {}
      },
      confirmDeleteBloque(bloque) {
        this.selectedBloque = bloque
        this.deleteBloqueDialog = true;
      },
      deleteBloque(){
        PerfilesBloquesService.borrarBloque(this.selectedBloque.xid).then(() => {
          this.cargarPerfiles();
          this.deleteBloqueDialog = false;
        }).catch((err) => {
          console.error(err)
          this.$toast.add({
            severity: 'error',
            summary:'Error al borrar el bloque',
            detail: err.message
          })
        })
      },
      saveBloque(){
        if(this.bloque.xtipo == 'li'){
          this.bloque.xcontenido = this.lis.join("&&");
        }else if(this.bloque.xtipo == 'accordion'){
          this.bloque.xcontenido = ''
          for(let i=0; i<this.accordionstitulos.length; i++){
            this.bloque.xcontenido += "&&"+this.accordionstitulos[i]+"##"+this.accordionstextos[i]+"##"+this.accordionslinks[i];
          }
          this.bloque.xcontenido = this.bloque.xcontenido.slice(2)
        }else if(this.bloque.xtipo == 'fieldset'){
          this.bloque.xcontenido = ''
          for(let i=0; i<this.accordionstitulos.length; i++){
            this.bloque.xcontenido += "&&"+this.fieldsetstitulos[i]+"##"+this.fieldsetstextos[i]+"##"+this.fieldsetslinks[i];
          }
          this.bloque.xcontenido = this.bloque.xcontenido.slice(2)
        }else if(this.bloque.xtipo == 'card'){
          this.bloque.xcontenido = this.bloque.cardsSubtitulo+"##";
          this.bloque.xcontenido += this.bloque.cardsKitDigitalPrecioSin+"##";
          this.bloque.xcontenido += this.bloque.cardsKitDigitalPrecioCon+"##";
          this.bloque.xcontenido += this.bloque.cardsParrafo+"##";
          if(this.bloque.cardsLista && this.bloque.cardsLista.length>0)
            this.bloque.cardsLista = this.bloque.cardsLista.join("||");
          else
            this.bloque.cardsLista ="";
          this.bloque.xcontenido += this.bloque.cardsLista+"##";
          this.bloque.xcontenido += this.bloque.cardsFooter;

        }
        //PREPARAMOS EL FormData
        let formDataBloque = new FormData();
        formDataBloque.append('xtitulo',this.bloque.xtitulo)
        formDataBloque.append('xto',this.bloque.xto)
        formDataBloque.append('xvisible',this.bloque.xvisible)
        formDataBloque.append('xtipo',this.bloque.xtipo)
        formDataBloque.append('xcontenido',this.bloque.xcontenido)
        formDataBloque.append('xperfilid',this.bloque.xperfilid)
        formDataBloque.append('xorden',this.bloque.xorden)
        if(this.bloque.subirImg){
          formDataBloque.append('subirImg',this.bloque.subirImg)
        }

        if(this.bloque.xid){
          //Update
          formDataBloque.append('xid',this.bloque.xid)
          PerfilesBloquesService.actualizarBloque(formDataBloque).then((response) =>{
            this.$toast.add({
              severity: response.data.success ? 'info' : 'error',
              summary: response.data.success ? 'Actualizado': 'Error',
              detail: response.data.description,
              life: response.data.success ? 10000 : null
            })
            this.cargarPerfiles()
            this.bloqueDialog = false;
          }).catch((error) =>{
            console.error(error)
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: error
            })
          })
        }else{
          //Nuevo
          PerfilesBloquesService.nuevoBloque(formDataBloque).then((response) =>{
            this.$toast.add({
              severity: response.data.success ? 'info' : 'error',
              summary: response.data.success ? 'Creado': 'Error',
              detail: response.data.description,
              life: response.data.success ? 10000 : null
            })
            this.bloqueDialog = false
            this.cargarPerfiles()
            this.bloqueDialog = false;
          }).catch((error) =>{
            console.error(error)
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: error
            })
          })
        }

      }


    },
    computed: {

    }
}
</script>

<style lang="scss">
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #f8b30577 !important;
}
.expand-column > .p-column-title {
  display: initial !important;
}
.p-datatable-row-expansion {
  color: #212529 !important;
  background-color: #e9ecef !important;
}
.v-home-perfiles {
}
.p-datatable .p-column-resizer-helper {
  background: #f8b505 !important;
}
.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  color: #ffffff !important;
  background: #d32f2f !important;
  border: 1px solid #d32f2f !important;
}
.p-paginator {
  .p-paginator-current {
    margin-left: auto;
  }
}

.p-progressbar {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #00acad;
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.p-datepicker {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

.p-datatable.p-datatable-customers {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
.p-datatable .p-sortable-column {
  width: auto;
}
.p-column-filter {
  width: 100%;
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}
.p-datatable .p-sortable-column.p-highlight:hover {
  background: #e9ecef;
  color: #f8b505 !important;
}
.p-datatable .p-sortable-column.p-highlight {
  background: #ffffff;
  color: #f8b505 !important;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #f8b505 !important;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #f8b3057a !important;
  outline: 0 none;
}
@media screen and (max-width: 64em) {
  .p-datatable {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4rem 1rem -0.4rem -0.4rem;
          font-weight: bold;
        }
      }
    }
  }
}
.p-paginator .p-paginator-current {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  color: #000 !important;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0 0 0 -1px;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  color: #f8b505 !important;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0 0 0 -1px;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  //background: #f8b30535!important;
  background: #f8b505 !important;
  border-color: #f8b505 !important;
  color: #ffffff;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30577 !important;
  border-color: #f8b505 !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: #f8b505 !important;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30577 !important;
  border-color: #f8b505 !important;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #f8b30535 !important;
  color: #000 !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30575 !important;
  border-color: #f8b505 !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #f8b505 !important;
  background: #f8b505 !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #d4a52d;
  background: #d4a52d;
  color: #ffffff !important;
}
.lista {
  list-style: none;
  &-item::before {
    content: "◉";
    color: #f8b305;
  }
}
</style>
