import axios from 'axios';
import API_URL from '../backend'
import Cookies from 'js-cookie';
import authHeader from './auth-header.service';


class DescargableService {
    getFolletosInfoServicios(nServicio){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/folletos/info/servicios/'+nServicio,
        }
        return axios(axiosRequest);
    }
    getFolletosInfoPerfiles(nPerfil){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/folletos/info/perfiles/'+nPerfil,
        }
        return axios(axiosRequest);
    }
    getDescargarFolleto(folleto){
        let cookiesDatosDescargas = JSON.parse(Cookies.get("descargableData"))
        const axiosRequest = {
            method: 'put',
            url:folleto.xdireccion,
            headers: { 'content-type': 'application/json' },
            data:{folleto:folleto, datos: cookiesDatosDescargas},
            responseType: 'blob'
        }
        console.log(axiosRequest)
        return axios(axiosRequest);
    }
    postSubirFolletoPerfiles(folleto){
        const axiosRequest = {
            method: 'post',
            url:API_URL+'/folletos/nuevoPerfil',
            headers: authHeader(),
            data:folleto
        }
        return axios(axiosRequest);
    }
    postSubirFolletoServicios(folleto){
        const axiosRequest = {
            method: 'post',
            url:API_URL+'/folletos/nuevoServicio',
            headers: authHeader(),
            data:folleto
        }
        return axios(axiosRequest);
    }
    borrarFolleto(xfolletoid){
        const axiosRequest = {
            method: 'delete',
            url:API_URL+'/folletos/borrar/'+xfolletoid,
            headers: authHeader(),
        }
        return axios(axiosRequest);
    }

}

export default new DescargableService();